body{
    margin: 0;
    background-color:#E4E4E4;
}

h2{
    color: rgb(0, 0, 0);
}

#game{
    position: relative;
}

#dice{
    width: 9px;
    margin-right:10px;
}

#players{
    display: flex;
    position: absolute;
}

#player-one, #player-two, #total{
    color: rgb(18, 26, 37);
    width: 175px;
}

#rules{
    float: right;
}

#player-one{
    margin-right: 8px;
}

#player-two{
    margin-left: 8px;
}

#new-game{
    border-style: solid;
}

#message{
    padding-top: 6px;
    position:absolute;
}

#rulesBtn{
    position:fixed;
    right:0;
}

#rules{
    position:fixed;
    right:0;
}

.open{
    width: 30%;
    border: 2px solid;
    background-color: rgba(128, 128, 128, 0.50);
}

.active{
    color:green;
    border: 3px solid;
    border-color: rgb(22, 139, 24);
}

.red{
    color:red;
}